import logo from '../assets/images/shay-logo-swirl.png'

export const Footer = () => {

    const scroll = (el) => {
        const target = document.querySelector(`.${el.target.value}`)
        if (target) {
            const extraHeight = 150 // Adjust the extra height as needed
            const targetOffsetTop = target.getBoundingClientRect().top + window.pageYOffset
            window.scrollTo({ top: targetOffsetTop - extraHeight, behavior: 'smooth' })
        }
    }

    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return <footer className="footer-wrapper full">
        <div className="footer main-layout">
            <div className="footer-data" >
                <div className="logo">
                    <div className="logo-img">
                        <img src={logo} alt="watercolor flower" />
                    </div>
                    <div className="logo-text">
                        <p>שי קופרמן</p>
                        <p>רפואה סינית ושיאצו</p>
                    </div>
                </div>

                <span className="divider" />

                <div className='text'>
                    <p>שי קופרמן מטפלת ברפואה סינית, שיאצו וצמחי מרפא</p>
                    <div className='info'>
                        <p>052-4585485</p>
                        <p>תל אביב</p>
                        <p>גדרה</p>
                    </div>
                    <div className="copyright">
                        <p>כל הזכויות שמורות &copy; {new Date().getFullYear()}</p>
                    </div>
                </div>

                <span className="divider" />

                <ul className='dictionary'>
                    <li><p><b>אקופונקטורה (דיקור):</b> שיטת טיפול ברפואה סינית, המתבצעת כשזרימת הצ'י (אנרגיה) בגוף, מושפעת בשל החדרת מחטים אל נקודות מסוימות בגוף.</p></li>
                    <li><p><b>"אש המינג-מן" (MING MEN):</b></p></li>
                    <li><p><b>גו צ'י (GU QI)</b></p></li>
                    <li><p><b>ג'יא'או (JIAO):</b></p></li>
                    <li><p><b>ג'ין- יה (JIN YE):</b></p></li>
                    <li><p><b>ג'ינג (JING):</b> תמצית החיים בגוף</p></li>
                    <li><p><b>גן (GAN):</b> מתוק</p></li>
                    <li><p><b>דאו (דאואיזם):</b></p></li>
                    <li><p><b>דאי מאי (DAI MAI):</b></p></li>
                </ul>

                <span className="divider" />
                <ul className='nav-buttons no-li-style'>
                    <li><button onClick={scroll} value="about">אודות</button></li>
                    <li><button onClick={scroll} value="treatments">טיפולים</button></li>
                    <li><button onClick={scroll} value="treatment-types">תחומי טיפול</button></li>
                    <li><button onClick={scroll} value="contact">צור קשר</button></li>
                    <li><button onClick={scrollToTop}>חזרה למעלה</button></li>
                </ul>
            </div>
        </div>
    </footer>
}