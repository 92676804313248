
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/css';
import img1 from '../assets/images/shay-pictures-compressed/16.jpg'
import img2 from '../assets/images/shay-pictures-compressed/2.jpg'
import img3 from '../assets/images/shay-pictures-compressed/3.jpg'
import img4 from '../assets/images/shay-pictures-compressed/4.jpg'
import img5 from '../assets/images/shay-pictures-compressed/5.jpg'
import img6 from '../assets/images/shay-pictures-compressed/6.jpg'
import img7 from '../assets/images/shay-pictures-compressed/7.jpg'
import img8 from '../assets/images/shay-pictures-compressed/8.jpg'
import img9 from '../assets/images/shay-pictures-compressed/9.jpg'
import img10 from '../assets/images/shay-pictures-compressed/10.jpg'
import img11 from '../assets/images/shay-pictures-compressed/11.jpg'
import img12 from '../assets/images/shay-pictures-compressed/12.jpg'
import img13 from '../assets/images/shay-pictures-compressed/13.jpg'
import img14 from '../assets/images/shay-pictures-compressed/14.jpg'
import img15 from '../assets/images/shay-pictures-compressed/15.jpg'

export const Carousel = () => {
    return <section className="carousel full" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
        <Splide hasTrack={true} options={
            {
                type: 'loop',
                drag: 'free',
                gap: '10px',
                autoWidth: true,
                extensions: { AutoScroll },
                autoScroll: {
                    pauseOnHover: true,
                    speed: 0.3,
                },
            }
        }
            extensions={{ AutoScroll }}
        >
            <SplideSlide> <img src={img1} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img2} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img3} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img4} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img5} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img6} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img7} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img8} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img9} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img10} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img11} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img12} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img13} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img14} alt="shiatsu massage" /></SplideSlide>
            <SplideSlide> <img src={img15} alt="shiatsu massage" /></SplideSlide>
        </Splide>
    </section>
}