
export const Hero = () => {
    return <section className="hero-wrapper full" id="hero">
        <div className="hero main-layout">
            <div className="hero-data" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <div className="hero-text" >
                    <div className="hero-text-title">
                        <h1>שי קופרמן</h1>
                        <h2>רפואה סינית ושיאצו</h2>
                    </div>
                    <h3>052-4585485</h3>
                    {/* <h4>"טאו
                        של השמיים להיטיב
                        לא להכאיב, טאו
                        של
                        החכם לעשות במקום להתלונן"
                    </h4> */}
                    <h3>SHAY CUPERMAN</h3>
                    <p>CHINESE MEDICINE & SHIATSU</p>
                </div>
            </div>
        </div>
    </section>
}