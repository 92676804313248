import leaf from '../assets/images/leaf.svg'

export const Believe = () => {
    return <section className="believe" >
        <img src={leaf} alt="" className='leaf top' />
        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">לאחר כעשור של לימודים, וכעשור וחצי של טיפולים בתחומים פרא-רפואיים ואינטגרטיביים, אני שמחה לפתוח את הקליניקה שלי בגדרה (ותל אביב). <br />
            במסגרת הקליניקה יערך אבחון מקיף שעל פיו תותאם תוכנית טיפולים אישית. <br /> <br /> הטיפול משלב הסתכלות רחבה ועבודה עם תחומי חיים שונים של המטופלים כמו התייחסות ל: שינה, תאבון וצמא, מידת ערנות, אנרגיה, תפקוד יומיומי, פעילות, הפגת מתחים ועוד. <br />
            במסגרת הטיפול ניתן לבחור בדיקור או בשיאצו, ואף מומלץ מאד לשלב ביניהם. במידת הצורך אשלב בתהליך גם צמחי מרפא והמלצות אשר יקלו, ואולי אף יסייעו למנוע הישנות בעיות או מחלות בעתיד. <br /> <br />
            חשוב לזכור כי הרפואה הסינית, הינה רפואה מונעת ופחות רפואה דחופה, ולכן משמעותי מאד שכל אחד ואחת ימצאו את הדרך הנכונה ביותר עבורם ללכת בה, על מנת לשפר את איכות חייהם.
        </p>
        <img src={leaf} alt="" className='leaf bot' />
    </section>
}