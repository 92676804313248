
import { Splide, SplideSlide } from '@splidejs/react-splide';

export const Recommendations = () => {
    return <section className="recommendations">
        <div className="recommendations-carousel" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
            <Splide hasTrack={true} options={{
                rewind: true,
                gap: '1rem',
                type: "loop",
                direction: "rtl",
                autoplay: true,
                perPage: 1,
            }
            }
            >
                <SplideSlide>
                    <div className="recommendation">
                        <div className="text-wrapper">
                            <div className="text">
                                <p>
                                    "שי מטפלת מדהימה ומיוחדת, עזרה לי המון אחרי הריון ולידה לא קלים,
                                    שדרשו התאוששות ארוכה. היא סבלנית, קשובה והצליחה להתאים טיפול,
                                    שנתן לי כוחות להתמודד עם מצב העומס הגופני והנפשי בו הייתי באותה התקופה.
                                    ממליצה בחום!"
                                </p>
                                <p className="recommender">מ.א., בת 33</p>
                            </div>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="recommendation">
                        <div className="text-wrapper">
                            <div className="text">
                                <p> "האמת שהופתעתי לטובה! אני מגיע מתחום המדעים המדויקים
                                    והיו לי הרבה ספקות לגבי התהליך. הטיפולים עם שי, הרגישו כמו
                                    מילוי מצברים לגוף."
                                </p>
                                <p className="recommender">ר.ד., בן 36</p>
                            </div>
                        </div>
                    </div></SplideSlide>
                <SplideSlide>        <div className="recommendation">
                    <div className="text-wrapper">
                        <div className="text">
                            <p>"הגעתי לטיפול אצל שי עקב בעיות פרקים, התחלתי סדרת טיפולים שכללו שיאצו ודיקור. היא תמיד
                                התעניינה בבעיות ובתסמינים שלי, והתרגשתי מאד כשיצרה קשר לאחר הטיפול לשאול לשלומי.
                                לאחר סיום הסדרה אני מרגישה הטבה משמעותית, אין ספק שהיא רצינית ומקצועית."
                            </p>
                            <p className="recommender">א.ק., בת 63</p>
                        </div>
                    </div>
                </div></SplideSlide>
                <SplideSlide>        <div className="recommendation">
                    <div className="text-wrapper">
                        <div className="text">
                            <p>"שי מדקרת מעולה, קשובה אמפתית ומקצועית.
                                האבחון שעשתה לי לפני הטיפול היה רציני ומעמיק.
                                ממליץ בחום להגיע לטיפול."
                            </p>
                            <p className="recommender">י.ג., בן 29</p>
                        </div>
                    </div>
                </div></SplideSlide>
                <SplideSlide>     <div className="recommendation">
                    <div className="text-wrapper">
                        <div className="text">
                            <p>"הגעתי אל הקליניקה של שי דרך המלצה של חברה, אחרי ששום טיפול מערבי לא עזר לי. קיבלתי משי יחס מדהים, חם ומקצועי. האווירה נעימה ורגועה,  והשילוב בין שיאצו לדיקור עם המלצות בענייני תזונה עזרו לי מאד. כעבור קצת יותר מחודשיים הטיפול החל להשפיע!"</p>
                            <p className="recommender">ר.ש., בת 49</p>
                        </div>
                    </div>
                </div></SplideSlide>
            </Splide>
        </div>





    </section>
}