import shay from '../assets/images/shay-pictures-compressed/1.jpg'

export const About = () => {
    return <section className="about-wrapper full">
        <div className="about main-layout">
            <div className="about-data">
                <div className="flex gap">
                    <div className="about-text" data-aos="fade-up-left" data-aos-delay="300" data-aos-duration="1000">
                        <ul>
                            <li><p>מוסמכת כמטפלת בכירה בשיאצו, ברפואה סינית וצמחי מרפא, מטעם קמפוס
                                ברושים, המועדון לספורט באוניברסיטת תל אביב.</p></li>
                            <li><p>מטפלת בקליניקה פרטית בגדרה באמצעות כלים מעולם השיאצו, הדיקור וצמחי
                                המרפא הסיניים בשילוב המלצות תזונה ואורחות חיים המותאמים למטופל.
                                מטפלת בכאב, החזרת איזון פיזי ורגשי במצבי לחץ, דיכאון, חרדה, הפרעות
                                שינה, הפרעות במצב הרוח כולל תסמונת קדם ווסתית בנשים, תופעות סביב גיל
                                המעבר, תמיכה בבעיות כרוניות ועוד.</p></li>
                            <li><p>מטפלת שיאצו במסגרת "מכבי טבעי" עם התמחות בטיפול בנשים במעגל החיים.</p></li>
                            <li><p>מטפלת בבעיות אורטופדיות בקליניקה של אלכס ספיר מומחה לפיזיותרפיה
                                אורתופדית ומטפל ברפואה משולבת – אינטגרטיבית בתל אביב.</p></li>
                            <li><p>מתמחה בקליניקה לטיפול במחלות לב - רפואת לב אינטגרטיבית במרפאה של
                                עמוס זיו.</p></li>
                            <li><p>מתנדבת בעמותת &#39;מדקרים ללא גבולות&#39; (AWB), מעגלי ריפוי בדיקור אוזן, אשר
                                נועדים לסייע בוויסות תגובות טראומטיות, בקרב אנשים אשר נחשפו לאירועים
                                טראומטיים.</p></li>
                            <li><p>חברה באיגוד הרפואה הסינית Dipl. C.M. (I.A.C.M).</p></li>
                            <button> <a href="/about" target='noopener'>לעוד מידע</a></button>
                        </ul>
                    </div>
                    <img src={shay} alt="shay cuperman portrait" data-aos="fade-up-right" data-aos-delay="200" data-aos-duration="1000" />
                </div>
                {/* <div className="flex gap">
                    <div className="about-text" data-aos="fade-up-left" data-aos-delay="350" data-aos-duration="1000">
                        <ul>
                            <li><p>מוסמכת כמטפלת בכירה בשיאצו, ברפואה סינית וצמחי מרפא, מטעם קמפוס ברושים, המועדון לספורט באוניברסיטת תל אביב.</p></li>
                            <li><p>מתמחה בקליניקה לטיפול במחלות לב - רפואת לב אינטגרטיבית במרפאה של עמוס זיו.</p></li>
                            <li><p>מוסמכת בעבודה סוציאלית מטעם המכללה האקדמית "תל חי".</p></li>
                            <li><p>הכשרה כקצינת מבחן לנוער בסיכון בקרית שמונה. </p></li>
                            <li><p>מוסמכת בטיפול ברכיבה טיפולית מטעם וינגייט.</p></li>
                        </ul>
                    </div>
                    <div className="about-text cert" data-aos="fade-up-left" data-aos-delay="250" data-aos-duration="1000">
                        <ul>
                            <li><p>חברה באיגוד הרפואה הסינית Dipl. C.M. (I.A.C.M).</p></li>
                            <li><p>רשומה בפנקס העובדים הסוציאליים.</p></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
}