import wuxing from '../assets/images/elements.jpg'

export const Treatments = () => {
    return <section className="treatments" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
        <img src={wuxing} alt="wu xing 5 chinese elements" draggable="false" />
        <div className="dropdowns">
            <div className='dropdown fire'>
                <div>
                    <h3>אש - לב ומעי דק</h3>
                    <h4>"ניצוצות של שמחה"</h4>
                </div>
                <ul>
                    <li><p>הפרעות שינה: קושי להירדם בלילה/ התעוררויות בלילה, תחושה שהשינה אינה מספקת, חלומות סוערים, שינה המופרעת ע"י חלומות</p></li>
                    <li><p>הלמות לב</p></li>
                    <li><p>הפרעות קצב (דופק בלתי סדיר, דופק מהיר/ איטי)</p></li>
                    <li><p>הזעה</p></li>
                    <li><p>קשיי ריכוז</p></li>
                    <li><p>חסר שקט</p></li>
                    <li><p>רתיעה מחום</p></li>
                    <li><p>טעם מר בפה</p></li>
                </ul>
            </div>
            <div className='dropdown earth'>
                <div>
                    <h3>אדמה - טחול וקיבה</h3>
                    <h4>"שגרה מבורכת"</h4>
                </div>
                <ul>
                    <li><p>רגישויות במערכת העיכול</p></li>
                    <li><p>כאבי בטן, בחילות, צרבות, רפלוקס, הקאות, גזים</p></li>
                    <li><p>עייפות כרונית/ לאות</p></li>
                    <li><p>בקעים & צניחות איברים</p></li>
                    <li><p>חסר תאבון/ תאבון מוגבר</p></li>
                    <li><p>מחשבות טורדניות</p></li>
                    <li><p>ריבוי דאגות</p></li>
                    <li><p>חולשת גפיים</p></li>
                    <li><p>נטייה לשטפי דם</p></li>
                    <li><p>דלקות חניכיים</p></li>
                    <li><p>פצעים בפה/ בלשון</p></li>
                    <li><p>ריח חריף מהפה</p></li>
                    <li><p>עצירות / שלשול</p></li>
                    <li><p>בצקות</p></li>
                    <li><p>כבדות</p></li>
                    <li><p>טחורים</p></li>
                    <li><p>חשק מוגבר למתוק</p></li>
                </ul>
            </div>
            <div className='dropdown metal'>
                <div>
                    <h3>מתכת - ריאות ומעי גס</h3>
                    <h4>"שחרור & LET GO"</h4>
                </div>
                <ul>
                    <li><p>	מערכת חיסון;
                        נטייה להתקררויות
                        כאבי גרון
                        דלקות ריאות חוזרות
                    </p></li>
                    <li><p>	קשיי נשימה (צפצופים, קושי בהכנסת/ הוצאת אויר)</p></li>
                    <li><p>	קול חלש</p></li>
                    <li><p>	אלרגיות</p></li>
                    <li><p>	נזלת כרונית</p></li>
                    <li><p>	ליחה</p></li>
                    <li><p>	ברונכיטיס</p></li>
                    <li><p>	צפצופי נשימה</p></li>
                    <li><p>	שיעול</p></li>
                    <li><p>	בעיות בעור</p></li>
                    <li><p>	גרון/ עור יבש</p></li>
                    <li><p>	צלקות רגישות / שאינן מחלימות</p></li>
                    <li><p>	תהליכי אבל ואבדן</p></li>
                    <li><p>	דימומים מהאף</p></li>
                    <li><p>	קואורדינציה</p></li>
                    <li><p>	דימוי גוף</p></li>
                    <li><p>	נטייה ליובש</p></li>
                </ul>
            </div>
            <div className='dropdown water '>
                <div>
                    <h3>מים - כליות ושלפוחית שתן</h3>
                    <h4>"סבלנות ששוחקת סלע"</h4>
                </div>
                <ul>
                    <li><p>	ליווי הריון והכנה ללידה</p></li>
                    <li><p>	תמיכה וחיזוק לאחר הפלה/ לידה שקטה</p></li>
                    <li><p>	גיל מעבר & גלי חום</p></li>
                    <li><p>	הזעות לילה</p></li>
                    <li><p>	חרדה</p></li>
                    <li><p>	נשירת שיער</p></li>
                    <li><p>	בעיות אוזניים</p></li>
                    <li><p>	בעיות בשתן (דלקות חוזרות, אצירה, גמגום, בריחות, ריח)</p></li>
                    <li><p>	ריבוי הפרשות ואגינליות</p></li>
                    <li><p>	רתיעה מקור</p></li>
                    <li><p>	טראומה ופוסט טראומה</p></li>
                    <li><p>	חשק מוגבר לטעם מלוח</p></li>
                    <li><p>	תחושת קור בבטן/ בגב </p></li>
                    <li><p>	חשק מיני- חסר/ מוגבר</p></li>
                </ul>
            </div>
            <div className='dropdown wood'>
                <div>
                    <h3>עץ - כבד וכיס מרה</h3>
                    <h4>"גמישות וצמיחה"</h4>
                </div>
                <ul>
                    <li><p>	מתח. לחץ, עצבנות, תסכול</p></li>
                    <li><p>	כאב: ראש, שרירים, מפרקים, רצועות וגידים</p></li>
                    <li><p>	מחזור חודשי & PMS</p></li>
                    <li><p>	שינויים במצב רוח</p></li>
                    <li><p>	שינווים ביציאות</p></li>
                    <li><p>	נטייה לקור בקצוות</p></li>
                    <li><p>	דיכאון</p></li>
                    <li><p>	בעיות עיניים</p></li>
                    <li><p>	קושי לקום בבוקר</p></li>
                    <li><p>	נעילת לסתות, חריקת שיניים</p></li>
                    <li><p>	רתיעה מרוח</p></li>
                    <li><p>	סחרחורת</p></li>
                    <li><p>	לחץ דם</p></li>
                    <li><p>	גרד</p></li>
                    <li><p>	טיקים</p></li>
                    <li><p>	רעד בגפיים</p></li>
                    <li><p>	טעם חמוץ</p></li>
                    <li><p>	מיגרנות</p></li>
                    <li><p>	ביישנות, הססנות</p></li>
                    <li><p>	קושי בקבלת החלטות</p></li>
                </ul>
            </div>
        </div>
    </section>
}