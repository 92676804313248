import { About } from "../components/about"
import { Believe } from "../components/believe"
import { Contact } from "../components/contact"
import { Treatments } from "../components/treatments.jsx"
import { Hero } from "../components/hero"
import { TreatmentTypes } from "../components/treatment-types.jsx"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react"
import { Carousel } from "../components/carousel.jsx"
import { Recommendations } from "../components/recommendations.jsx"

export const Home = () => {

    useEffect(() => {

        AOS.init({
            once: true,
            offset: 200,
        });

    }, [])

    return <section className="home main-layout">
        <Hero />
        <Believe />
        <About />
        <Carousel />
        <Treatments />
        <TreatmentTypes />
        <Recommendations />
        <Contact />
    </section>
}