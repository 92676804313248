import Aos from "aos";
import { useEffect } from "react";

export const About = () => {

    useEffect(() => {

        Aos.init({
            once: true,
            offset: 200,
        });

    }, [])

    return <section className="about-wrapper full">
        <div className="about main-layout about-page">
            <div className="about-data" data-aos="fade-up" data-aos-duration="1000">


                <div className="about-text" >
                    <ul>
                        <h3>השכלה אקדמית והכשרה</h3>
                        <li><p>מוסמכת כמטפלת בכירה בשיאצו, ברפואה סינית וצמחי מרפא, מטעם קמפוס ברושים, המועדון לספורט באוניברסיטת תל אביב.</p></li>
                        <li><p>מתמחה בקליניקה לטיפול במחלות לב - רפואת לב אינטגרטיבית במרפאה של עמוס זיו.</p></li>
                        <li><p>מוסמכת בעבודה סוציאלית מטעם המכללה האקדמית "תל חי".</p></li>
                        <li><p>הכשרה כקצינת מבחן לנוער בסיכון בקרית שמונה. </p></li>
                        <li><p>מוסמכת בטיפול ברכיבה טיפולית מטעם וינגייט.</p></li>
                    </ul>
                </div>     <div className="flex gap">
                    <div className="about-text" >
                        <ul>
                            <h3>ניסיון מקצועי</h3>
                            <li><p>כיום מטפלת בקליניקה פרטית בגדרה באמצעות כלים מעולם השיאצו, הדיקור וצמחי המרפא הסיניים בשילוב המלצות תזונה ואורחות חיים המותאמים למטופל. מטפלת בכאב, החזרת איזון פיזי ורגשי במצבי לחץ, דיכאון, חרדה, הפרעות שינה, הפרעות במצב הרוח כולל תסמונת קדם ווסתית בנשים, תופעות סביב גיל המעבר, תמיכה בבעיות כרוניות ועוד.</p></li>
                            <li><p>עוסקת בטיפולי שיאצו במסגרת "מכבי טבעי" עם התמחות בטיפול בנשים במעגל החיים.</p></li>
                            <li><p>מטפלת בבעיות אורטופדיות בקליניקה של אלכס ספיר מומחה לפיזיותרפיה אורתופדית ומטפל ברפואה משולבת – אינטגרטיבית בתל אביב.</p></li>
                            <li><p>מתנדבת בעמותת 'מדקרים ללא גבולות' (AWB), מעגלי ריפוי בדיקור אוזן, אשר נועדים לסייע בוויסות תגובות טראומטיות, בקרב אנשים אשר נחשפו לאירועים טראומטיים.</p></li>
                            <li><p>קשיים מוטוריים, ASD, הפרעות התנהגותיות וקשיים רגשיים וחברתיים.</p></li>
                            <li><p>עבדתי כעובדת סוציאלית במסגרות סגורות עם ילדים ונערות בסיכון, וכן עם אוכלוסיות בעלות צרכים מיוחדים.</p></li>
                            <li><p>טיפלתי בחוות לרכיבה טיפולית באוכלוסיות מיוחדות במגוון גילאים, בניהם נכים, הפרעות תקשורת והפרעות קשב ADHD.</p></li>
                        </ul>
                    </div>
                </div>
                <div className="about-text cert">
                    <ul>
                        <h3>חברות באגודות וארגונים</h3>
                        <li><p>חברה באיגוד הרפואה הסינית Dipl. C.M. (I.A.C.M).</p></li>
                        <li><p>רשומה בפנקס העובדים הסוציאליים.</p></li>
                    </ul>
                </div>
            </div>

        </div>
    </section>
}