import { useRef } from "react"
import logo2 from '../assets/images/shay-logo-swirl.png'

export const Header = () => {
    const navRef = useRef()
    // const wazeRef = useRef()

    const scroll = (el) => {
        const target = document.querySelector(`.${el.target.value}`)
        // if (target) target.scrollIntoView({ inline: 'nearest', })
        if (target) {
            const extraHeight = 150 // Adjust the extra height as needed
            const targetOffsetTop = target.getBoundingClientRect().top + window.pageYOffset
            window.scrollTo({ top: targetOffsetTop - extraHeight, behavior: 'smooth' })
        }
        toggleHamburger(el)
        enableScroll()
    }

    const scrollToTop = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

    const toggleHamburger = (hamburger) => {
        hamburger.target.classList.toggle('active')
        navRef.current.classList.toggle('active')
        const isActive = hamburger.target.classList.contains('active')

        if (isActive) {
            disableScroll()
        } else {
            enableScroll()
        }
    }

    // const toggleWaze = (el) => {
    //     wazeRef.current.classList.toggle('active')
    // }
    const disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }

    // Enable scrolling
    const enableScroll = () => {
        document.body.style.overflow = 'auto'
    }

    return <section className="header-wrapper full" id="header">
        <div className="header main-layout">
            <nav className="flex space-between">
                <div className="logo" onClick={scrollToTop}>
                    <div className="logo-img">
                        <img src={logo2} alt="" />
                    </div>
                    <div className="logo-text">
                        <p>שי קופרמן</p>
                        <p>רפואה סינית ושיאצו</p>
                    </div>
                </div>
                <ul className="flex" ref={navRef}>

                    <li><button onClick={scroll} value="about">אודות</button></li>
                    <li><button onClick={scroll} value="treatments">טיפולים</button></li>
                    <li><button onClick={scroll} value="treatment-types">תחומי טיפול</button></li>
                    <li><button onClick={scroll} value="contact">צור קשר</button></li>
                    {/* <li className="waze" onClick={toggleWaze}>
                        <svg className="waze" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#141414" d="M502.2 201.7C516.7 287.5 471.2 369.6 389 409.8c13 34.1-12.4 70.2-48.3 70.2a51.7 51.7 0 0 1 -51.6-49c-6.4 .2-64.2 0-76.3-.6A51.7 51.7 0 0 1 159 479.9c-33.9-1.4-58-34.8-47-67.9-37.2-13.1-72.5-34.9-99.6-70.8-13-17.3-.5-41.8 20.8-41.8 46.3 0 32.2-54.2 43.2-110.3C94.8 95.2 193.1 32 288.1 32c102.5 0 197.2 70.7 214.1 169.7zM373.5 388.3c42-19.2 81.3-56.7 96.3-102.1 40.5-123.1-64.2-228-181.7-228-83.5 0-170.3 55.4-186.1 136-9.5 48.9 5 131.4-68.8 131.4C58.2 358.6 91.6 378.1 127 389.5c24.7-21.8 63.9-15.5 79.8 14.3 14.2 1 79.2 1.2 87.9 .8a51.7 51.7 0 0 1 78.8-16.4zM205.1 187.1c0-34.7 50.8-34.8 50.8 0s-50.8 34.7-50.8 0zm116.6 0c0-34.7 50.9-34.8 50.9 0s-50.9 34.8-50.9 0zm-122.6 70.7c-3.4-16.9 22.2-22.2 25.6-5.2l.1 .3c4.1 21.4 29.9 44 64.1 43.1 35.7-.9 59.3-22.2 64.1-42.8 4.5-16.1 28.6-10.4 25.5 6-5.2 22.2-31.2 62-91.5 62.9-42.6 0-80.9-27.8-87.9-64.3z" /></svg>
                        <div className="waze-options" ref={wazeRef}>
                            <button>תל אביב</button>
                            <button>גדרה</button>
                        </div>
                    </li> */}
                    <li>
                        <a href="https://wa.me/+972524585485" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                        </a>
                    </li>
                    <li>
                        <svg className="phone" onClick={() => window.open("tel:+972524585485")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#141414" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                    </li>
                </ul>
                <button className="hamburger hamburger--spring-r" type="button" onClick={toggleHamburger}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </nav>
        </div>
    </section>
}