import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { NotFound } from "./pages/not-found";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { About } from "./pages/about";

function App() {
  
  return <div className="app">
    <Header />
    <Routes>
      <Route path='*' element={<NotFound />} />
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
    </Routes>
    <Footer />
  </div>
}

export default App;
