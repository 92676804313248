import pulse from '../assets/images/pulse.jpg'
import tongue from '../assets/images/tongue.jpg'
import back from '../assets/images/back.jpg'
import stomach from '../assets/images/stomach.jpg'
import meridians from '../assets/images/meridians.jpg'
import plants1 from '../assets/images/plants/plants1.jpg'
import plants2 from '../assets/images/plants/plants2.jpg'
import plants3 from '../assets/images/plants/plants3.jpg'
import plants4 from '../assets/images/plants/plants4.jpg'
import plants5 from '../assets/images/plants/plants5.jpg'

export const TreatmentTypes = () => {
    return <section className="treatment-types">
        <h2 className="title">תחומי טיפול</h2>

        <div className="details flex column gap">
            <details open data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                <summary >אבחון</summary>
                <div className="details-open-text diagnosis test">
                    <h3>אבחון בראי חמשת האלמנטים: </h3>
                    <p>בפגישה הראשונה, נערך אבחון אישי מקיף, בראי הרפואה הסינית, להתאמת הטיפול המדויק ביותר עבורך. האבחון ברפואה הסינית הינו כלי מפתח לטיפול וכולל תשאול מפורט.
                        נסקור יחד מערכות שונות בגוף, כדי למפות סימפטומים שמעידים על כך שהאיזון הופר, ונמצא את האופן להשבתו לפעילות תקינה.

                        <br />  <br /> בנוסף,  יערך אבחון על פי הלשון (מומלץ לא לצחצח לשון לפני הטיפול), הדופק והמרידיאנים (ערוצי אנרגיה הזורמים לאורך הגוף), הגב והבטן במידת הצורך.
                        אופני אבחון מגוונים, מאפשרים הצלבת מידע מהגוף בדרכים שונות, מתוך הנחה כי טיפול טוב מתחיל באבחון נכון של שורש הבעיה, וכי הגוף מציג בפנינו את האבחנה הנכונה.

                    </p>
                    <p>דוגמאות לשיטות אבחון שונות:</p>
                    <ol>
                        <li>
                            <h3>אבחנת לשון</h3>
                            <img src={tongue} alt="אבחנת לשון" draggable="false" />
                        </li>
                        <li>
                            <h3>אבחנת גב</h3>
                            <img src={back} alt="אבחנת גב" draggable="false" />
                        </li>
                        <li>
                            <h3>אבחנת בטן</h3>
                            <img src={stomach} alt="אבחנת בטן" draggable="false" />
                        </li>
                        <li>
                            <h3>אבחנת מרידיאנים</h3>
                            <img src={meridians} alt="אבחנת מרידיאנים" draggable="false" />
                        </li>
                        <li className='pulse'>
                            <h3>אבחנת דופק</h3>
                            <img src={pulse} alt="אבחנת דופק" draggable="false" />
                        </li>
                        <li>
                            <h3>תשאול מקיף</h3>
                        </li>
                    </ol>
                </div>
            </details>

            <details data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                <summary>רפואה סינית</summary>
                <div className="details-open-text">
                    <p>לפני כ-5,000 שנים החלו להתפתח תאוריות אשר נוצרו מהתבוננות בטבע: תנועת הכוכבים בשמיים, מחזוריות השמש, הירח ועונות השנה, התנהגות בעלי חיים, הצמחים והמזון בסביבה. מתוך ההתבוננות בטבע, רפואת המזרח הבינה כי האדם הינו מיקרו-קוסמוס של המאקרו-קוסמוס, כלומר, התפתחה ההבנה שמה שקורה מחוץ לגוף, קורה גם בתוך הגוף.
                        במהותה, הרפואה הסינית הינה רפואה המונעת התפתחות או החרפת מחלות; היא שואפת ליצירת איזון והרמוניה בין המערכות הגוף השונות, הרגשיות והפיזיות, בהתאמה עם גורמי הטבע לאורך תהליכי החיים (רגש, תנועה, טעם, תחושות,  חוויות, שינה, תזונה והרגלי חיים שונים).
                        הרפואה הסינית כוללת מיגוון אפשרויות לטיפול כמו: דיקור, צמחי מרפא ותזונה אישית.
                        חשוב לציין, כי השפעת הטיפול, הינה בדרך כלל השפעה מצטברת, מתחזקת ונשמרת לאורך סדרת הטיפולים, אולם קצב השינוי והשימור תלויים בגורמים רבים, שהמשמעותי ביותר ביניהם הוא המוטיבציה ליצירת שינוי.
                    </p>
                    <ol>
                        <li>
                            <h3> דיקור (אקופונקטורה): </h3>
                            <p>
                                שיטת טיפול "אלכימית" ברפואה סינית, המתבצעת כשזרימת הצ'י (אנרגיה) בגוף, מושפעת בשל החדרת מחטים עדינות (ללא חומרים) אל נקודות הממוקמות בצמתים משמעותיים בגוף. המחטים מייצרות תנועה של דם ואנרגיה לאזור. שילוב של מספר נקודות הנבחרות בהתאמה לכל טיפול, מייצרות יחד תנועה חזקה של ריפוי והשבת הגוף לתפקודו המקורי.
                            </p>
                        </li>
                        <br />
                        <li>
                            <h3>צמחי מרפא "תורת חומרי הרפואה"</h3>
                            <p>
                                לפני אלפי שנים, טרם עידן המעבדות, החיסונים והתרופות, כשהגוף יצא מאיזון, הדרך הזמינה והמהירה ליצירת שינוי פנימי, הייתה באמצעות שימוש בצמחי המרפא ומינרלים שונים מהאדמה. מבחינה היסטורית השימוש בצמחי המרפא קדם לרפואת הדיקור, כך, תורת החומרים הפכה לרפואה עממית והחלה לשמש ככלי מרכזי בטיפול, מאז ועד היום.
                            </p>
                            <ol className="li-asterisk">
                                <h4>הגורמים המשפיעים על פעילות הצמחים: </h4>
                                <li><p> <b>טעם</b> (חריף משפיע על הריאות, מתוק מחזק את הטחול, מר מנקה את הלב, מלוח מחזק את הכליות, חמוץ משחרר את הכבד,  וחסר טעם- משתן).</p></li>
                                <li><p> <b>טמפרטורה</b>; (מחמם מאד, חמים, ניטרלי, קריר, מקרר מאד).</p></li>
                                <li><p> <b>איברים</b> עליהם משפיע הצמח הנובעים מתנועה אנרגטית, כיוון, טעם וטמפרטורה.</p></li>
                                <li><p> <b>חלקים שונים של הצמח  </b>(פרי, פרח, עלה, ענף, גזע/ קליפה, שורש, זרע) .</p></li>
                                <li><p><b>מיקום בו גדל הצמח</b>, פעילותו והתנהגותו בטבע לעיתים מרמזים על השפעותיו.</p></li>
                                <li><p> <b>צורה</b> (צמח שענפיו נראים כלשון נחש יכול לטפל בהכשות).</p></li>
                            </ol>

                            <ol className="li-asterisk">
                                <br />
                                <h4>אופן לקיחת פורמולות צמחי מרפא:</h4>
                                <li><p> <b>בישול</b> צמחים גולמיים בסיר מים - הרתחה, סינון, קירור ושתיית הנוזל המסונן.</p></li>
                                <li><p> <b>אבקה</b> - צמחים שיובשו ונטחנו, עורבבו יחד בהתאם למרשם. יש להמיס בכוס עם מעט מים רותחים ולערבב עד לקבלת נוזל אחיד. יש להוסיף מעט מים קרים ולשתות.</p></li>
                                <li><p> <b>קפסולות</b> - אבקות ממרשם שעורבבו יחד וקופסלו. מיועד לאנשים אשר מתקשים עם ריחות וטעמים דומיננטיים. תהליך הקפסול מייקר מעט את הפורמולה.</p></li>
                            </ol>
                        </li>
                        {/* <li>
                            <h3>צמחי מרפא "תורת חומרי הרפואה"</h3>
                            <p>תזונה והמלצות אורח חיים - (הוספת פסקה קצרה)</p></li> */}
                    </ol>
                    <div className='herbal'>
                        <img src={plants1} alt="herbal medicine mix" />
                        <img src={plants2} alt="herbal medicine mix" />
                        <img src={plants3} alt="herbal medicine mix" />
                        <img src={plants4} alt="herbal medicine mix" />
                        <img src={plants5} alt="herbal medicine mix" />
                    </div>
                </div>
            </details>

            <details data-aos="fade-right" data-aos-delay="700" data-aos-duration="1000">
                <summary>שיאצו</summary>
                <div className="details-open-text shiatsu">
                    <p>השיאצו, הינו טיפול במגע שמקורו ביפן. פירוש המילה "שיאצו" הינו 'לחץ אגודל'; הוא מתבסס על תורת הרפואה הסינית, בשילוב רפואה יפנית ופיזיותרפיה מהמערב.
                        הטיפול נעשה באמצעות לחיצות כפות הידיים על גבי המרידיאניים. <br /> <br />
                        מטרת הטיפול היא שחרור זרימת הדם והאנרגיה בצורה חופשית בגוף, ויצירת איזון בין אזורים בעודף (מתוחים/ עמוסים/ נוקשים) לעומת כאלה בחוסר (שקועים, עמוקים, רגישים).
                        <br /> <br />
                        מנפלאות השיאצו: הקלה ושחרור הכאב, איזון בין הגוף לנפש ושחרור מקומות חסומים או תקועים, וויסות רגשי ותמיכה בבעיות כרוניות.
                        חשוב לציין, כי הטיפול נעשה בלבוש מלא.
                    </p>
                </div>
            </details>

            <details data-aos="fade-right" data-aos-delay="800" data-aos-duration="1000">
                <summary>מוקסה וכוסות רוח</summary>
                <div className="details-open-text cup-moxa">
                    <ol className="no-li-style">
                        <li>
                            <h3>מוקסה</h3>
                            <p>אחד מתוך מאות צמחי המרפא הנמצאים בשימוש ברפואה הסינית, משמש באופן "מסורתי" לטיפול על ידי החום הנוצר מהבערה שלו; שמו בלטינית: "Artemisiae- Argyi", בסינית: "AI YE", ובאנגלית: "Mugwort". <br /> <br />
                                צמח המוקסה אמנם נלקח מעולם צמחי המרפא,
                                אך השימוש העיקרי שלו הוא על ידי בערה,
                                לכן ניתן לסווגו לקטגוריה עצמאית.
                                <br />
                                השימוש בצמח עתיק יותר מהדיקור נפוץ עד היום,
                                בכפרים מרוחקים, שלמדו מה"סבתא" מה לעשות איתו ומתי.
                                עלי הצמח מיובשים ומעובדים לצורות שונות;
                                סיגר דק או עבה, גלילים שמניחים על גבי המחט,
                                בתפזורת דקה או גסה ועוד.
                                <br /><br />
                                טיפולי המוקסה יכולים להתבצע גם ללא שילוב של דיקור במחטים.
                                החום הנפלט מבערת הצמח, בעל סגולות מרפא חזקות.
                                בספרות הרפואית הקדומה נכתב כי
                                "מוקסה יכולה לרפא מאה מחלות שונות"; <br />
                                <span>(המספר מאה, משמש ברפואה הסינית כמטאפורה למספר גדול של דברים).</span>
                                <br /><br />
                            </p></li>
                        <li>
                            <h3>כוסות רוח</h3>
                            <p>
                                שיטת טיפול הכרוכה במשיכת הצ'י והדם אל פני שטח העור, באמצעות שימוש בוואקום הנוצר בתוך כוס זכוכית או במבוק (היום גם סיליקון). <br /> <br />
                                השיטה הינה חלק מהרפואה הסינית מזה אלפי שנים, אך נפוצה בחלקים שונים בעולם, ועדיין ממשיכה לשגשג. <br /> <br />
                                נשתמש בכוסות רוח במצבים מסוימים של כיווץ או כאבי שרירים, ובהתקררות, בעיקר בשלב ההתחלתי של המחלה.
                            </p>
                        </li>
                        <li>
                            <br /> <br />
                            <h3>תזונה והמלצות אורח חיים</h3>
                            <p>
                                &quot;המזון יכול לסלק גורמי מחלה מהגוף, להסדיר את תפקודם של חמישה איברים
                                פנימיים (לב, ריאות, כבד, טחול וכליות); לענג את החושים, להזין את הדם ולחדש את
                                האנרגיה.&quot; סאן סי-מיאו מרפא מפורסם משושלת טאנג (618-907), טען כי לפני
                                הטיפול בצמחי המרפא ובדיקור, חשוב לנסות קודם את הטיפול התזונתי. <br /> <br />
                                השפעת המזון על הבריאות ואיכות החיים, הינה הבסיס ההגיוני עליו מתבססת
                                התיאוריה הטיפולית של תורת התזונה הסינית העתיקה. <br /> <br />
                                התזונה ברפואה הסינית הינה כלי המאפשר שמירה על בריאות ואורך חיים נכון של
                                הגוף. תפקודי האיברים הפנימיים משתנים על פי התזונה שלנו, תזונה מאוזנת ונכונה
                                עבורנו, מאפשרת למכונה המשוכללת שלנו אנרגיה לחשוב, לפעול ולעכל מידע (מזון
                                ומחשבה).  <br /> <br />
                                הגוף שלנו הינו מכונה משוכללת, אשר זקוקה לאנרגיה מהמזון (חלבונים, ויטמינים,
                                מינרליים ועוד) בכדי לבצע תפקודים שונים.  <br /> <br />
                                בעבר, היינו מלקטים, צדים או דגים מזון מהטבע לסיר ומשם לצלחת, אנשים אכלו
                                מה שהיה זמין בסביבתם הקרובה. היום, השפע הגדול מסביב, מקשה לעיתים על
                                בחירת מזון המתאים עבורנו, אנחנו אוכלים עם העיניים, אבל לא כל מה שנראה טוב-
                                אכן עושה לנו טוב.  <br /> <br />
                                בניית תפריט התזונה אשר מתבסס על האבחון הסיני, אינו מחליף טיפול תרופתי,
                                אלא משלים אותו.  <br /> <br />
                                המזון, על פי הרפואה הסינית, מתחלק על פי מאפיינים שונים כמו:
                            </p>
                            <li>
                                <ol className="li-asterisk">
                                    <li><p>טעם: חריף, מתוק, חמוץ, מלוח, מר</p></li>
                                    <li><p>אנרגיה: קרור, חימום, ייזוע, ספיחה, שיתון</p></li>
                                    <li><p>תחום הפעילות: לב, ריאות, כבד, טחול וכליות</p></li>
                                    <li><p>כיוון התנועה: מעלה, מוריד, מכנס, מכווץ, מוציא</p></li>
                                </ol>
                            </li>
                        </li>
                        <div className="table flex column">
                            <div className="flex">
                                <div className="taste">
                                    <h3>מתוק - טחול</h3>
                                    <p>
                                        (חמים- YANG)
                                        דגנים (אורז, חיטה),
                                        אגוזים, ערמונים, זרעים (דלעת, חמנייה), דבש,
                                        קטניות, סויה, טופו, פירות
                                        בשר (בקר, כבש, עוף),
                                        חלב, ביצים, ירקות שורש
                                        (גזר, בטטה, תפו"א),
                                        אפונה, מלפפון, תירס, תרד
                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>חמוץ - מתוק</h3>
                                    <p>
                                        עגבנייה, שעועית אדומה,
                                        יוגורט, לאבנה,
                                        לחם על בסיס מחמצת:
                                        -	שיפון/ כוסמין
                                        פירות: פטל, ענבים, ליצ'י
                                        אגס, אננס, אפרסק, מנגו
                                        אשכולית, קלמנטינה, תפוז, אוכמניות, תות שדה,
                                        תפוח עץ   ...

                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>חריף - ריאות</h3>
                                    <p>
                                        (חם- YANG)
                                        שום, ג'ינג'ר, קינמון,
                                        פלפל/ צ'ילי, חזרת,
                                        אגוז מוסקט,
                                        ארוגולה , שומר, חרדל,
                                        מנטה, בזיליקום,
                                        כוסברה, פטרוזיליה,
                                        מיורן
                                        F</p>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="taste">
                                    <h3>חמוץ - כבד
                                        (קריר- YIN)
                                    </h3>
                                    <p>

                                        לימון/ ליים, תפוח ירוק,
                                        ירקות כבושים
                                        ...
                                        חמוץ מר - חומץ
                                        חמוץ חריף - כרישה
                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>מלוח - כליות
                                        (קריר- YIN)</h3>
                                    <p>
                                        דגים, אצות ים,
                                        רוטב סויה/ מיסו,
                                        חמוצים מותססים במלח,
                                        אומבושי   ...
                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>מר - לב
                                        (קר- YIN)</h3>
                                    <p>
                                        סלרי, זיתים,
                                        אלפלפא, רוקט,
                                        ארטישוק, מיזונה,
                                        לענה (שיבא), קמומיל,
                                        שיפון
                                    </p>
                                </div>

                            </div>
                            <div className="flex">
                                <div className="taste">
                                    <h3>חריף- מתוק</h3>
                                    <p>
                                        קליפת קינמון
                                        אורז מלא,
                                        אניס, נענע,
                                        צנון, לפת
                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>ארומטי</h3>
                                    <p>
                                        זעתר, אורגנו, טימין, לוונדר
                                        עפיץ - תה, יין, פירות בוסר
                                    </p>
                                </div>
                                <div className="taste">
                                    <h3>מתוק - מר
                                    </h3>
                                    <p>
                                        אמאנט, קינואה
                                        סלרי, דלעת, חסה,
                                        פפאיה, אשכולית,
                                        קפה
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className='text-small'>*יש צורך באבחון ובניית תכנית טיפול מותאמת אישית, אין לראות במידע זה המלצה רפואית</p>
                    </ol>
                </div>
            </details>
        </div>
    </section>
}